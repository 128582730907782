import * as React from 'react'
import styles from './AlbumCover.module.css'

  const cont = {
    backgroundColor: 'black',
    cursor: 'pointer',
    overflow: 'hidden', 
    float: 'left' as 'left',
    position: 'relative' as 'relative'
  }

 const AlbumCover = ({ index, onClick, photo, margin, key }) => {  
  return (
    <div style = {{ margin, width: photo.width, ...cont }} key={key}>
      <img className ={styles.albumCover} {...photo} onClick={(e) => onClick(e, { index, photo })} />
      <div className= {styles.title}>
            <div className="text">
              <span>{photo.title}</span>
            </div>
      </div>
    </div>
  )
};

export default AlbumCover;