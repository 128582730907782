import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Gallery from 'react-photo-gallery';
import AlbumCover from '../album-cover/AlbumCover';
import * as styles from './home.module.css';
import { PhotoAlbum } from '../models/PhotoAlbum';
import { PhotoInfo, StoragePhoto } from '../models/PhotoInfo';
import Amplify, { API } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { photos } from "../../photos";
import 'isomorphic-fetch';

export class Home extends React.Component<RouteComponentProps<{}>, {photos: PhotoInfo[], photosData: PhotoAlbum[]}> {

    constructor(props) {
        super(props);
        this.state = { photos: [], photosData: [] };
        this.openAlbum = this.openAlbum.bind(this);

        //Amplify.configure(awsconfig);
        this.fetchImages();
      }

      async fetchImages() {
        this.loadData(photos);
      }

      // async fetchImages() {

      //   const myInit = { 
      //     headers: {
      //       "Content-Type": "application/json"
      //     },
      //   };

      //   //let data = await API.get('photos', '/photos', myInit);        
      //   fetch('https://' + awsconfig.aws_user_files_s3_bucket + '.s3.amazonaws.com/public/photos.json')
      //       .then(response => response.json())
      //       .then(items => this.loadData(items));
      // }

      async loadData(data) {
        data = await Promise.all(data.map(async image => {
          let result: StoragePhoto = {
            signedImage: 'https://' + image.bucket + '.s3.amazonaws.com/public/' + image.path,
            path: image.path,
            folderPath: image.path.substr(0, image.path.indexOf("/")),
            width: +image.width,
            height: +image.height
          }        
          return result;
        }));

        let covers = data.filter(item => item.path.indexOf("AlbumCover") > -1);
        
        let photosArray = new Array();
        covers.forEach(async element => {            
            photosArray.push({src: element.signedImage, width:element.width, height:element.height, title: element.folderPath, key: element.folderPath});
        });
        this.setState({ photos: photosArray, photosData: covers });
      }

      openAlbum(event, obj) {
        this.props.history.push('/gallery/' + this.state.photosData[obj.index].folderPath)
      }

    public render() {
        return <div>
            <h1>Natasha Smirnova</h1>
            <Gallery photos={this.state.photos} onClick={this.openAlbum} renderImage={AlbumCover} targetRowHeight={500} />
            <footer className={styles.footer} >copyright (C) 2007-2099 Natasha Smirnova. all rights reserved. </footer>
        </div>;
    }
}