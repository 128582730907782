export const photos = [
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Alexandra/17.jpg",
    "width": "533",
    "height": "800"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Alexandra/3.jpg",
    "width": "800",
    "height": "536"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Alexandra/6.jpg",
    "width": "800",
    "height": "533"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Alexandra/AlbumCover.jpg",
    "width": "800",
    "height": "533"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/094c2a09_3175_4c4b_b397_d2705963d1ff.jpg",
    "width": "700",
    "height": "461"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/12.jpg",
    "width": "700",
    "height": "677"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/1cefcd39_f9df_40b5_a627_5db5a3862aae.jpg",
    "width": "700",
    "height": "366"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/1e533a52_d609_4481_bd2f_07bba11ec59e.jpg",
    "width": "699",
    "height": "458"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/25.jpg",
    "width": "460",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/2da3358f_99e4_47b3_9300_d3211f036591.jpg",
    "width": "700",
    "height": "674"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/34_1.jpg",
    "width": "700",
    "height": "426"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/4dc236a1_43c4_45a0_a198_761697474e10.jpg",
    "width": "700",
    "height": "480"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/5052030f_21f0_4adc_8ff1_c75af2eefed1.jpg",
    "width": "700",
    "height": "465"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/5c53b27a_d2d2_4132_860a_97c0b76acdad.jpg",
    "width": "700",
    "height": "475"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/6598a5a5_1e2f_43ed_a3dd_664152ffd527.jpg",
    "width": "700",
    "height": "495"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/678565a6_4382_431c_8c1b_0ee10ca7f296.jpg",
    "width": "481",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/7e9ff54a_63d5_4997_9e73_58b51e676b78.jpg",
    "width": "696",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/8a554711_8e96_4c68_a4b0_c3a581500ee0.jpg",
    "width": "699",
    "height": "472"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/8bad9e05_ae84_48cd_8b22_3a0f5a2adbad.jpg",
    "width": "700",
    "height": "470"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/AlbumCover.jpg",
    "width": "700",
    "height": "463"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/cd712382_8eb2_496e_a79b_e99aba78f6f9.jpg",
    "width": "700",
    "height": "475"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/d49a7151_85a7_4022_b133_298f5371c65d.jpg",
    "width": "700",
    "height": "549"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/d84d947d_e5f9_4b6f_8ef6_8673cbf0978a.jpg",
    "width": "700",
    "height": "505"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/eba0468a_90c5_4392_a17f_649bc88ee7ac.jpg",
    "width": "616",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Altai/fbc1059e_fc81_41b5_8f14_3e34532cddc8.jpg",
    "width": "700",
    "height": "485"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/1cc86b6f_fb2f_4712_8344_eadfbf702095.jpg",
    "width": "700",
    "height": "683"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/2.jpg",
    "width": "700",
    "height": "468"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/8e0a675d_df83_4fa4_b2b5_10fb2d178695.jpg",
    "width": "458",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/AlbumCover.jpg",
    "width": "431",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/DSC03248.jpg",
    "width": "700",
    "height": "470"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/ab49113b_95fa_42f4_ac7c_3b9b59887eb5.jpg",
    "width": "451",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/c74ec0d3_b321_484d_b7c2_5e895a13f68d.jpg",
    "width": "700",
    "height": "440"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/ccac81ff_14a8_487c_b46a_4597582bd8bb.jpg",
    "width": "700",
    "height": "685"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/cff6196b_a47c_4155_9b75_70c78f9c2e08.jpg",
    "width": "700",
    "height": "468"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/fdb19455_a4a5_4bad_b628_5da2ea6cc80b.jpg",
    "width": "451",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/fec583a3_3e1b_4c77_bb07_30c6469d5589.jpg",
    "width": "700",
    "height": "429"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Belorussia/i3.jpg",
    "width": "470",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/1.jpg",
    "width": "470",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/18.jpg",
    "width": "800",
    "height": "800"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/253771.jpg",
    "width": "700",
    "height": "463"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/296507.jpg",
    "width": "501",
    "height": "699"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/61c9a71e_cc27_41df_aba6_d2fafe8e5aa5.jpg",
    "width": "700",
    "height": "497"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/8ee51b98_3796_4433_9e17_39f73921f436.jpg",
    "width": "479",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/AlbumCover.jpg",
    "width": "449",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/DSC01737.jpg",
    "width": "469",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/DSC01746.jpg",
    "width": "469",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/DSC05082.jpg",
    "width": "464",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/DSC05112.jpg",
    "width": "700",
    "height": "481"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/DSC05114.jpg",
    "width": "470",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/cd8a7c86_fb5c_4150_9ba5_7bb9592f887d.jpg",
    "width": "450",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/d145f8db_06b5_4795_80e1_a38c5e511926.jpg",
    "width": "464",
    "height": "699"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Eugenia/e5e88493_32ac_4a50_b249_ef470d3f81b8.jpg",
    "width": "700",
    "height": "625"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/1.jpg",
    "width": "585",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/10.jpg",
    "width": "526",
    "height": "599"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/11.jpg",
    "width": "516",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/12.jpg",
    "width": "528",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/13.jpg",
    "width": "516",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/14.jpg",
    "width": "518",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/15.jpg",
    "width": "498",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/2.jpg",
    "width": "557",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/4.jpg",
    "width": "588",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/5.jpg",
    "width": "600",
    "height": "598"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/6.jpg",
    "width": "587",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/8.jpg",
    "width": "522",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/9.jpg",
    "width": "506",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Italy/AlbumCover.jpg",
    "width": "520",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Ladoga/1.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Ladoga/AlbumCover.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Ladoga/DSC08995.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Ladoga/DSC09057.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Ladoga/DSC09062.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Ladoga/DSC09154.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Ladoga/DSC09167.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Leningrad/4bc10b9b_32a9_4305_bdbf_02dee37f1de7.jpg",
    "width": "527",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Leningrad/AlbumCover.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Leningrad/DSC06250.jpg",
    "width": "469",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Leningrad/DSC08722.jpg",
    "width": "469",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Leningrad/DSC08908.jpg",
    "width": "469",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Leningrad/b010e4c0_900a_4adc_af5d_210de5aefa17.jpg",
    "width": "699",
    "height": "443"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/AlbumCover.jpg",
    "width": "502",
    "height": "750"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC00847.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC00859.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01008.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01026.jpg",
    "width": "502",
    "height": "750"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01047.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01128.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01137.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01398.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01415.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01417.jpg",
    "width": "502",
    "height": "750"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01423.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01428.jpg",
    "width": "750",
    "height": "502"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Mexico/DSC01469.jpg",
    "width": "502",
    "height": "750"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/2eef9344_4401_49dc_944a_e71b93d8a78d.jpg",
    "width": "487",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/2fde0c8f_83a9_47ac_acd3_806de2180079.jpg",
    "width": "465",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/4e3c8091_0fae_4d34_8a37_67cb3dbbe851.jpg",
    "width": "465",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/5dd2863f_65df_40c9_8c82_9f554d322dc8.jpg",
    "width": "700",
    "height": "465"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/70bb15d3_2d34_4162_8ad4_0c1f64e7e6d0.jpg",
    "width": "700",
    "height": "465"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/7325fa38_49ae_4fb9_acdf_f54a3b8a5d91.jpg",
    "width": "700",
    "height": "465"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/744312b9_da23_473a_995c_2d714b8c8f97.jpg",
    "width": "457",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/7effd67e_869a_4503_acd1_327ec82b0882.jpg",
    "width": "700",
    "height": "465"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/8374e1a0_94d1_45b8_b645_3338c291660b.jpg",
    "width": "465",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/914c07a1_d30a_41c5_a05f_db515d68b5b4.jpg",
    "width": "465",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/AlbumCover.jpg",
    "width": "464",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/accecde6_716f_40e1_b5a6_6699bc890744.jpg",
    "width": "451",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Montenegro/f77e8f91_1b1d_425e_a229_6f375c66394d.jpg",
    "width": "700",
    "height": "593"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000220031.jpg",
    "width": "900",
    "height": "597"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000230013_.jpg",
    "width": "900",
    "height": "597"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000230017_.jpg"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000240026_.jpg",
    "width": "530",
    "height": "800"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000220003.jpg",
    "width": "533",
    "height": "800"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000220019.jpg",
    "width": "900",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000220027.jpg",
    "width": "900",
    "height": "597"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000240032.jpg",
    "width": "900",
    "height": "597"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000240034_.jpg",
    "width": "615",
    "height": "923"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000260024.jpg",
    "width": "534",
    "height": "800"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000240035.jpg",
    "width": "900",
    "height": "597"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000250014.jpg",
    "width": "900",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000270008_.jpg"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000270031.jpg",
    "width": "900",
    "height": "597"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/000000270038_.jpg",
    "width": "900",
    "height": "600"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Nepal/AlbumCover.jpg",
    "width": "900",
    "height": "597"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/AlbumCover.jpg",
    "width": "700",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/DSC04242.jpg",
    "width": "700",
    "height": "470"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/DSC04290.jpg",
    "width": "700",
    "height": "469"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/DSC04617.jpg",
    "width": "700",
    "height": "459"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/DSC04620.jpg",
    "width": "700",
    "height": "470"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/DSC04629.jpg",
    "width": "700",
    "height": "470"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/DSC04670.jpg",
    "width": "700",
    "height": "456"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/DSC04721.jpg",
    "width": "700",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Norway/Untitled_221.jpg",
    "width": "700",
    "height": "450"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Other/1.jpg",
    "width": "700",
    "height": "468"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Other/2.jpg",
    "width": "700",
    "height": "468"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Other/31.jpg",
    "width": "700",
    "height": "486"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Other/AlbumCover.jpg",
    "width": "469",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Other/DSC01480.jpg",
    "width": "700",
    "height": "475"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Other/a9e486f8_f836_42bd_a4a2_0317df889465.jpg",
    "width": "461",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Prague/2e5ccc1b_b672_4e9f_9dd3_20788760bf5c.jpg",
    "width": "448",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Prague/398531.jpg",
    "width": "427",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Prague/49e61a4c_044a_4d70_a9a2_08fd99a24e8f.jpg",
    "width": "428",
    "height": "699"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Prague/632432ea_b935_4de8_98b7_bd0439522526.jpg",
    "width": "470",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Prague/724c8ac2_1e9b_40ee_a46f_0a2b6e7a27bf.jpg",
    "width": "449",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Prague/78969dbc_2962_4475_ba71_dc306f815353.jpg",
    "width": "700",
    "height": "494"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Prague/AlbumCover.jpg",
    "width": "472",
    "height": "699"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Prague/fce13ad3_69a2_45ec_994b_a5bb3b4c9a53.jpg",
    "width": "445",
    "height": "699"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/AlbumCover.jpg",
    "width": "700",
    "height": "447"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_103_2.jpg",
    "width": "700",
    "height": "449"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_104_2.jpg",
    "width": "700",
    "height": "449"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_106.jpg",
    "width": "700",
    "height": "449"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_16.jpg",
    "width": "453",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_27.jpg",
    "width": "451",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_52.jpg",
    "width": "446",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_58.jpg",
    "width": "700",
    "height": "447"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_63.jpg",
    "width": "700",
    "height": "474"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_64.jpg",
    "width": "700",
    "height": "474"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_7.jpg",
    "width": "449",
    "height": "700"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_86.jpg",
    "width": "700",
    "height": "446"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_9.jpg",
    "width": "700",
    "height": "449"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_96.jpg",
    "width": "700",
    "height": "445"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_97.jpg",
    "width": "700",
    "height": "445"
  },
  {
    "bucket": "natasha-smirnova-website141834-dev",
    "path": "Uzbekistan/Untitled_99.jpg",
    "width": "700",
    "height": "445"
  }
]