import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Lightbox from 'react-images';
import Gallery from 'react-photo-gallery';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import 'isomorphic-fetch';
import {PhotoInfo} from '../models/PhotoInfo'
import Amplify, { API } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { photos } from "../../photos";

export class GalleryViewer extends React.Component<RouteComponentProps<{}>, 
    {currentImage: number, lightboxIsOpen: boolean, photos: PhotoInfo[], albumName: string, loading: boolean}> {

    constructor(props) {
        super(props);
        this.state = { currentImage: 0, lightboxIsOpen: false, loading: false, photos: [], albumName: '' };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);

        //Amplify.configure(awsconfig);

        this.fetchImages(props.match.params.folder);
      }

      async fetchImages(path) {
        
        //let data = await API.get('photos', '/photos', { headers: { "Content-Type": "application/json" }});

        // fetch('https://' + awsconfig.aws_user_files_s3_bucket + '.s3.amazonaws.com/public/photos.json')
        //     .then(response => response.json())
        //     .then(items => this.loadData(path,items));

        //this.loadData(path, data)
        
        this.loadData(path, photos)
      }

      async loadData(path, data) {
        data = await data.filter(item => item.path.indexOf(path) > -1);

        data = await Promise.all(data.map(async image => {
          let result: PhotoInfo = {
            src: 'https://' + image.bucket + '.s3.amazonaws.com/public/' + image.path,
            width: +image.width,
            height: +image.height
          }        
          return result;
        }));

        this.setState({ photos: data, albumName: path, loading: false, currentImage: 0, lightboxIsOpen: false });
      }

      openLightbox(event, obj) {
        this.setState({
          currentImage: obj.index,
          lightboxIsOpen: true,
        });
      }
      closeLightbox() {
        this.setState({
          currentImage: 0,
          lightboxIsOpen: false,
        });
      }
      gotoPrevious() {
        this.setState({
          currentImage: this.state.currentImage - 1,
        });
      }
      gotoNext() {
        this.setState({
          currentImage: this.state.currentImage + 1,
        });
      }

    public render() {
        return <div style={{ width: '96vw' }}>
        <h1><Link to="/"> home</Link> / {this.state.albumName}</h1>
        <Gallery photos={this.state.photos} onClick={this.openLightbox} targetRowHeight={500}/>
        <Lightbox images={this.state.photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </div>;
    }
}
