import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './css/site.css';
// import 'bootstrap';
import { BrowserRouter } from 'react-router-dom';
import * as RoutesModule from './routes';
let routes = RoutesModule.routes;
import * as serviceWorker from './serviceWorker';

import GA4React from "ga-4-react";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
    
ReactDOM.render(<BrowserRouter children={ routes } basename={ baseUrl } />, document.getElementById('root'));

try {
    setTimeout(_ => {
      const ga4react = new GA4React("G-WYP50P2J6Y");
      ga4react.initialize();
    }, 4000);
  } catch (err) {}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
